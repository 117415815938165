var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":_vm.is_loading.submit ? '#b0a8ff' : '#38B22D',"title":null,"subtitle":null,"shape":"square","finish-button-text":_vm.is_loading.submit ? 'Saving...' : 'Save'},on:{"on-complete":function($event){return _vm.formSubmitted()}}},[_c('tab-content',{attrs:{"title":"Email Notification Details","icon":"feather icon-file-text","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"p-2",attrs:{"autocomplete":"off","id":"email-notification-form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email Notification Title","label-for":"email-notification-title"}},[_c('validation-provider',{attrs:{"name":"Email Notification Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email-notification-title","name":"title","state":errors.length > 0 ? false : null,"placeholder":"Email Notification Title"},model:{value:(_vm.data_local.title),callback:function ($$v) {_vm.$set(_vm.data_local, "title", $$v)},expression:"data_local.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._l((_vm.customFields),function(item,index){return _c('b-form-group',{key:item.id,staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":item.name,"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(item.type == 'number')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":item.name,"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data['field_' + item.id],"placeholder":item.label,"type":"number","state":_vm.getValidationState(validationContext)}})],1):_vm._e(),(item.type == 'text')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":item.name,"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data['field_' + item.id],"placeholder":item.label,"type":"text","state":_vm.getValidationState(validationContext)}})],1):_vm._e(),(item.type == 'url')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":item.name,"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data['field_' + item.id],"placeholder":item.label,"type":"url","state":_vm.getValidationState(validationContext)}})],1):_vm._e(),(item.type == 'rating')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('star-rating',{attrs:{"show-rating":false,"star-size":30},model:{value:(
                        _vm.data_local.get_customfields_data['field_' + item.id]
                      ),callback:function ($$v) {_vm.$set(_vm.data_local.get_customfields_data, 'field_' + item.id, $$v)},expression:"\n                        data_local.get_customfields_data['field_' + item.id]\n                      "}}),(
                        _vm.data_local.get_customfields_data['field_' + item.id]
                      )?_c('span',{class:("rating-btn btn " + (_vm.ratingdescription[
                          _vm.data_local.get_customfields_data['field_' + item.id]
                        ].class))},[_vm._v(" "+_vm._s(_vm.ratingdescription[ _vm.data_local.get_customfields_data["field_" + item.id] ].text))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.data_local.get_customfields_data['field_' + item.id]
                      ),expression:"\n                        data_local.get_customfields_data['field_' + item.id]\n                      "}],attrs:{"type":"hidden","name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]")},domProps:{"value":(
                        _vm.data_local.get_customfields_data['field_' + item.id]
                      )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data_local.get_customfields_data, 'field_' + item.id, $event.target.value)}}})],1)]):_vm._e(),(item.type == 'password')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":item.name,"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data['field_' + item.id],"placeholder":item.label,"type":"password","state":_vm.getValidationState(validationContext)}})],1):_vm._e(),(item.type == 'textarea')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('b-form-textarea',{attrs:{"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data['field_' + item.id],"trim":"","placeholder":item.label}})],1):_vm._e(),(item.type == 'date')?_c('b-form-group',{attrs:{"label":item.label,"label-for":"input-1"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'Y-m-d' },"name":("custom_fields_data[" + (item.name) + "-" + (item.id) + "]"),"value":_vm.data_local.get_customfields_data['field_' + item.id],"placeholder":"Select Date.."}})],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],1)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }